
<template>
<div>
  <div v-if="!isLoading" class="row mb-4">
    <div v-if="user" class="col-12">
      <h5 class="mb-3">User Details</h5>
      <div class="row g-3">
        <div class="col-md-4 order-md-2">
          <div class="position-sticky" style="top: 90px;">
            <div class="card bg-white overflow-hidden">
              <div class="card-body">
                <div class="">
                  <div class="profile-user position-relative d-inline-block mx-auto mb-4">
                    <img @click="showProfileImg(user.image)" :src="absoluteUrl(user.image)"
                      class="rounded-circle avatar-xl img-thumbnail
                       user-profile-image cursor-pointer" alt="user-profile-image" />
                  </div>
                  <h5 class="fs-16 mb-1">
                    {{user.first_name}} {{user.last_name}}
                  </h5>
                  <p class="mb-0"> <span v-if="user.role" class="text-capitalize">{{user.role.name}}</span> / Joined on {{$filters.date(user.created_at)}}</p>
                </div>
                <div v-if="user.id_card" class="mt-4 col-10 col-xxl-4">
                  <div style="border-radius:2rem;" class="card mb-0 text-center">
                    <img @click="showProfileImg(user.id_card)" :src="absoluteUrl(user.id_card)" class="cursor-pointer card-img-top" alt="Group head Id">
                  </div>
                </div>
              </div>
              <div class="circles-container" ><div class="circle-back" ></div><div class="circle-front" ></div></div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="d-flex align-items-start justify-content-between">
                <div class="flex-grow-1">
                  <div class="row g-5">
                    <div class="col-sm-6 col-md-4">
                        <label class="form-label fw-bold">First name</label>
                        <div>{{user.first_name}}</div>
                    </div>
                    <div class="col-sm-6 col-md-4">
                        <label class="form-label fw-bold">Middle name</label>
                        <div>{{user.middle_name}}</div>
                    </div>
                    <div class="col-sm-6 col-md-4">
                        <label class="form-label fw-bold">Last name</label>
                        <div>{{user.last_name}}</div>
                    </div>
                    <div class="col-sm-6">
                        <label class="form-label fw-bold">Email</label>
                        <div>{{user.email}}</div>
                    </div>
                    <div class="col-sm-6">
                        <label class="form-label fw-bold">Phone</label>
                        <div>{{user.mobile}}</div>
                    </div>
                    <div class="col-sm-6">
                      <label class="form-label fw-bold">Residential Address</label>
                      <div style="word-break: break-word;" v-if="user.residential_address">
                        <span class="">{{ user.residential_address.street_address }}</span> <br />
                        <span class="" v-if="user.residential_address.lga">{{ user.residential_address.lga.name }}</span>,
                        <span class="" v-if="user.residential_address.state">{{ user.residential_address.state.name }}</span>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <label class="form-label fw-bold">Polling Unit Address</label>
                      <div style="word-break: break-word;" v-if="user.home_address">
                        <span class="" v-if="user.home_address.unit">{{ user.home_address.unit.name }}</span> <br />
                        <span class="" v-if="user.home_address.ward">{{ user.home_address.ward.name }}</span>,
                        <span class="" v-if="user.home_address.lga">{{ user.home_address.lga.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />
</div>
</template>
<script>

import IsLoading from '@/components/IsLoading.vue'

export default {
  name: "user-show",
  components: {
    IsLoading,
  },
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    user(){
      return this.$store.state.userList.user
    }
  },
  methods:{
    async fetchItem(){
      if(this.$route.params.userId == this.activeUser.id){
        this.$router.push({path: '/account'}); return;
      }
      this.$store.dispatch("userList/fetchUser", this.$route.params.userId)
        .then((response) => {
          this.isLoading = false
          if(!response.data.success){
            this.$router.push({path: '/error-404'})
          }
      })
    },
    initializeAll(){
    }
  },
  created() {
    this.fetchItem()
  }
}

</script>
<style scoped>
.user-photo{
 width:10rem;
}
.circles-container {
    position: absolute;
    bottom: 0;
    top:0;
    width: 20%;
    right: -20%;
}

.circle-back, .circle-front{
    background-color: #e1f6e2;
    position: relative;
    width: 200%;
    padding-top: 200%;
    padding-bottom: 0;
    border-radius: 100%;
    display: inline-block
}
.circle-back {
    opacity: .6;
    z-index: 3;
    margin-left: -108%;
    margin-top: 0
}

.circle-front {
    opacity: .5;
    margin-bottom: 50%;
    margin-left: -209%;
    z-index: 4
}
</style>
